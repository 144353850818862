<template>
    <div class="page-box">
        <div class="page-title flex-center between-box">
            <span>当前位置：项目管理 > <b>到款</b></span>
            <div class="flex-center" @click="backPage">
                <i class="iconfont iconjiantou-copy-copy"></i>返回
            </div>
        </div>
        <div class="sub-merchants-info">
            <span>到款</span>
            <div class="sub-merchants-container">
                <div class="input-item">
                    <div class="input-item-title">{{$getTitleByType('会议名称',pid.cid)}}</div>
                    <el-select
                        ref="pName"
                            v-model="pName"
                            filterable
                        :placeholder="`请选择${$getTitleByType('会议名称',pid.cid)}`"
                            @change="proChange">
                        <el-option
                                v-for="(item, index) in projectOptions"
                                :key="item.id"
                                :label="item.name"
                                :value="index">
                        </el-option>
                    </el-select>
                </div>
                <div class="input-item">
                    <div class="input-item-title">所属学会</div>
                    <el-select
                            v-model="society"
                            :placeholder="`请选择所属学会（请选择${$getTitleByType('会议名称',pid.cid)}）`"
                            @change="societyChange"
                            @visible-change="clickProject">
                        <el-option
                                v-for="item in societyOptions"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="input-item">
                    <div class="input-item-title">{{$getTitleByType('会议日期',pid.cid)}}</div>
                    <el-input
                        v-model="pid.time"
                        :placeholder="`请选择${$getTitleByType('会议名称',pid.cid)}`"
                        disabled
                    ></el-input>
                </div>
                <div class="input-item">
                    <div class="input-item-title">{{$getTitleByType('大会主席',pid.cid)}}</div>
                    <el-input
                        v-model="pid.chairman"
                        :placeholder="`请选择${$getTitleByType('会议名称',pid.cid)}`"
                        disabled
                    ></el-input>
                </div>
                <div class="input-item">
                    <div class="input-item-title">招商申请日期</div>
                    <el-select
                            v-model="agreementName"
                            placeholder="请选择招商申请日期"
                            @change="agreementChange"
                            @visible-change="clickProject">
                        <el-option
                                v-for="(item, index) in agreementOptions"
                                :key="item.id"
                                :label="item.name"
                                :value="index">
                        </el-option>
                    </el-select>
                </div>
                <div class="input-item">
                    <div class="input-item-title">企业名称</div>
                    <el-input
                            v-model="corporate_name"
                            placeholder="请输入企业名称（需和合同企业全称一致）"
                            readonly
                    ></el-input>
                </div>
                <div class="input-item">
                    <div class="input-item-title">到款金额</div>
                    <el-input
                            v-model="amounts"
                            type="number"
                            @mousewheel.native.prevent
                            placeholder="请输入到款金额"
                            @input="setAmount(0)"
                    ></el-input>
                    <div class="money">万元</div>

                    <div class="money-count">
                        合同余额<span>{{ yue }}</span
                    >万元
                    </div>
                </div>
                <div class="tip-red" v-if="parseFloat(Number(amounts).toFixed(6))>parseFloat(Number(yue).toFixed(6))">
                    到款金额大于现有合同可用金额
                </div>
                <div class="input-item">
                    <div class="input-item-title">到款专项金额</div>
                    <el-input
                            v-model="self_amount"
                            type="number"
                            @mousewheel.native.prevent
                            placeholder="请输入到款专项金额"
                            @input="setAmount(1)"
                    ></el-input>
                    <div class="money">万元</div>
                    <div class="money-count">
                        专项余额<span>{{ self }}</span
                    >万元
                    </div>
                </div>
                <div class="input-item">
                    <div class="input-item-title">到款会议金额</div>
                    <el-input
                            v-model="meeting_decimal"
                            type="number"
                            @mousewheel.native.prevent
                            placeholder="请输入到款会议金额"
                            @input="setAmount(2)"
                    ></el-input>
                    <div class="money">万元</div>
                    <div class="money-count">
                        会议余额<span>{{ meet }}</span
                    >万元
                    </div>
                </div>
                <div class="input-item">
                    <div class="input-item-desc">备注</div>
                    <el-input
                            type="textarea"
                            resize="none"
                            :rows="5"
                            v-model="remark"
                            placeholder="请输入"
                    ></el-input>
                </div>
                <div class="input-item">
                    <div class="input-item-title">到款日期</div>
                    <el-date-picker
                            v-model="daokuan_date"
                            type="datetime"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="请选择到款日期"
                    >
                    </el-date-picker>
                </div>
                <approvalList
                        :typeClassify="4"
                        :trueName="trueName"
                        :copyMembers="copyMembers"
                        @setMember="setMember"
                        @setApproval="setApproval"
                ></approvalList>

                <el-button type="primary" @click="subApprovalSubmit" :loading="requestState" :disabled="requestState" >提交</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import ElInput from "../../../node_modules/element-ui/packages/input/src/input.vue";
    import ElButton from "../../../node_modules/element-ui/packages/button/src/button.vue";
    import approvalList from "../../components/approvalList.vue";

    export default {
        components: {
            ElButton,
            ElInput,
            approvalList,
        },
        name: "payment",
        data() {
            return {
                id: "",
                sign_code: "",

                society: "",
                societyOptions: [],

                pid: "",
                pName: "",
                projectOptions: [],

                agreementName: "",
                agreementId: "",
                yue: "0",
                meet: "0",
                self: "0",
                agreementOptions: [],

                name: "",
                corporate_name: "",
                contacts: "",
                amounts: "",
                self_amount: "",
                meeting_decimal: "",
                remark: "",
                daokuan_date: "",
                shenpi: "",
                copy: "",
                trueName: {
                    true_name: "",
                    user_id: "",
                },

                copyMembers: [],

                requestState:false
            };
        },
        created() {
            this.id = this.$route.query.id ? this.$route.query.id : '';
            this.$emit("setIndex", [4, 10]);
            this.daokuan_date = this.$tools.ZHDate(new Date(),6)
            this.init();
        },
        methods: {
            setAmount(type) {
                if (type === 0) {
                    this.amounts = this.amounts.replace(/[^0-9 .]/g, "");
                    this.self_amount = "";
                    this.meeting_decimal = "";
                } else if (type === 1) {
                    if (this.amounts) {
                        this.self_amount = this.self_amount.replace(/[^0-9 .]/g, "");
                        if (this.self_amount) {
                            this.meeting_decimal = parseFloat((
                                Number(this.amounts).toFixed(6) - Number(this.self_amount).toFixed(6)).toFixed(6));
                        } else {
                            this.meeting_decimal = parseFloat(Number(this.amounts).toFixed(6));
                        }
                    } else {
                        if (this.self_amount && this.meeting_decimal) {
                            this.amounts = parseFloat((
                                Number(this.meeting_decimal).toFixed(6) + Number(this.self_amount).toFixed(6)).toFixed(6));
                        }
                    }
                } else {
                    this.meeting_decimal = this.meeting_decimal.replace(/[^0-9 .]/g, "");
                    if (this.amounts) {
                        if (this.meeting_decimal) {
                            this.self_amount = parseFloat((
                                Number(this.amounts).toFixed(6) - Number(this.meeting_decimal).toFixed(6)).toFixed(6));
                        } else {
                            this.self_amount = parseFloat(Number(this.amounts).toFixed(6));
                        }
                    } else {
                        if (this.self_amount && this.meeting_decimal) {
                            this.amounts = parseFloat((
                                Number(this.meeting_decimal).toFixed(6) + Number(this.self_amount).toFixed(6)).toFixed(6));
                        }
                    }
                }
            },
            subApprovalSubmit(){
                if (!this.pid) {
                    this.$message.error("请选择会议名称");
                    return;
                }
                if (!this.society) {
                    this.$message.error("请选择所属学会");
                    return;
                }
                if (!this.agreementId) {
                    this.$message.error("请选择上游协议");
                    return;
                }
                if (!this.corporate_name) {
                    this.$message.error("请输入企业名称");
                    return;
                }
                if (!this.amounts) {
                    this.$message.error("请输入到款金额");
                    return;
                }
                if (this.self_amount === "") {
                    this.$message.error("请输入到款专项金额");
                    return;
                }
                if (this.meeting_decimal === "") {
                    this.$message.error("请输入到款会议金额");
                    return;
                }
                if (parseFloat(Number(this.amounts).toFixed(6)) > parseFloat(Number(this.yue).toFixed(6)) ||
                    parseFloat(Number(this.self_amount).toFixed(6)) > parseFloat(Number(this.self).toFixed(6))||
                    parseFloat(Number(this.meeting_decimal).toFixed(6)) > parseFloat(Number(this.meet).toFixed(6))) {
                    let that = this;
                    that
                        .$confirm("填写金额大于剩余金额", "温馨提示", {
                            confirmButtonText: "知晓",
                            cancelButtonText: "取消",
                            type: "warning",
                        })
                        .then(() => {
                            that.subApproval()
                        })
                        .catch(() => {
                        });
                }else {
                    this.subApproval()
                }
            },
            //  提交审核
            subApproval() {
                if (!this.pid) {
                    this.$message.error(this.$refs.pName.placeholder);
                    return;
                }
                if (!this.society) {
                    this.$message.error("请选择所属学会");
                    return;
                }
                if (!this.agreementId) {
                    this.$message.error("请选择上游协议");
                    return;
                }
                if (!this.corporate_name) {
                    this.$message.error("请输入企业名称");
                    return;
                }
                if (!this.amounts) {
                    this.$message.error("请输入到款金额");
                    return;
                }
                if (this.self_amount === "") {
                    this.$message.error("请输入到款专项金额");
                    return;
                }
                if (this.meeting_decimal === "") {
                    this.$message.error("请输入到款会议金额");
                    return;
                }
                if (!this.daokuan_date) {
                    this.$message.error("请选择到款日期")
                    return
                }
                this.requestState = true
                //添加
                this.$api.apiContent
                    .approvalDk({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        sid: this.society,
                        pid: this.pid.id,
                        sign_code: this.sign_code,
                        zid: this.agreementId,
                        name: this.name,
                        corporate_name: this.corporate_name,
                        amount: this.amounts,
                        self_amount: this.self_amount,
                        meeting_decimal: this.meeting_decimal,
                        remark: this.remark,
                        daokuan_date: this.daokuan_date,
                        shenpi: this.shenpi,
                        copy: this.copy,
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                                sid: this.society,
                                pid: this.pid.id,
                                sign_code: this.sign_code,
                                zid: this.agreementId,
                                name: this.name,
                                corporate_name: this.corporate_name,
                                amount: this.amounts,
                                self_amount: this.self_amount,
                                meeting_decimal: this.meeting_decimal,
                                remark: this.remark,
                                daokuan_date: this.daokuan_date,
                                shenpi: this.shenpi,
                                copy: this.copy,
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        this.requestState = false
                        this.$message.success(res.msg);
                        this.$router.push("send");
                    })
                    .catch((e) => {
                        this.requestState = false
                        this.$message.error(e.msg);
                    });
            },
            societyChange(){
                this.agreementName = "";
                this.agreementId = "";
                this.corporate_name = "";
                this.yue = "0";
                this.self = "0";
                this.meet = "0";
            },
            //  选择协议
            agreementChange(index) {
                this.agreementName = this.agreementOptions[index].name;
                this.agreementId = this.agreementOptions[index].id;
                this.yue = this.agreementOptions[index].yue;
                this.self = this.agreementOptions[index].self;
                this.meet = this.agreementOptions[index].meet;
                this.corporate_name = this.agreementOptions[index].corporate_name;
                this.trueName = {
                    true_name: this.agreementOptions[index].true_name,
                    user_id: this.agreementOptions[index].user_id,
                };
                this.shenpi = this.agreementOptions[index].user_id;
            },
            //  获取协议
            getAgreement() {
                this.$api.apiContent
                    .getZhaoshangDao({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        id: this.pid.id,
                        sid: this.society,
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                                id: this.pid.id,
                                sid: this.society,
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        res.data.map(item => {
                            item.name = item.name +"(" + item.corporate_name +")";
                        });
                        this.agreementOptions = res.data;
                        if(this.agreementOptions.length > 0){
                           if (this.agreementId){
                               let item = this.agreementOptions.filter((item) => {
                                   return item.id === this.agreementId;
                               })[0];
                               this.agreementName = item.name;
                               this.agreementId = item.id;
                               this.corporate_name = item.corporate_name;
                               this.yue = item.yue;
                               this.self = item.self;
                               this.meet = item.meet;
                               this.trueName = {
                                   true_name: item.true_name,
                                   user_id: item.user_id,
                               };
                               this.shenpi = item.user_id;
                           }else {
                               this.agreementName = this.agreementOptions[0].name;
                               this.agreementId = this.agreementOptions[0].id;
                               this.corporate_name = this.agreementOptions[0].corporate_name;
                               this.yue = this.agreementOptions[0].yue;
                               this.self = this.agreementOptions[0].self;
                               this.meet = this.agreementOptions[0].meet;
                               this.trueName = {
                                   true_name: this.agreementOptions[0].true_name,
                                   user_id: this.agreementOptions[0].user_id,
                               };
                               this.shenpi = this.agreementOptions[0].user_id;
                           }
                        }else {
                            this.agreementName = "";
                            this.agreementId = "";
                            this.corporate_name = "";
                            this.yue = "0";
                            this.self = "0";
                            this.meet = "0";
                        }
                    })
                    .catch(() => {
                        this.agreementName = "";
                        this.agreementId = "";
                        this.corporate_name = "";
                        this.agreementOptions = [];
                    });
            },
            //  选择会议
            proChange(index) {
                let value = this.projectOptions[index];
                value.time =
                    this.$tools.ZHDate(new Date(value.start_time * 1000)) +
                    " 至 " +
                    this.$tools.ZHDate(new Date(value.end_time * 1000));
                this.pName = value.name;
                this.pid = value;

                this.agreementName = "";
                this.agreementId = "";
                this.corporate_name = "";
                this.yue = "0";
                this.trueName = {
                    true_name: "",
                    user_id: "",
                };
                this.shenpi = "";
                this.society = "";
                this.getSociety();
            },
            //  选择所属学会点开后没选择
            clickProject(view) {
                if (!this.pid && view) {
                    this.$message.error("请选择会议名称");
                }
            },
            getSociety() {
                this.$api.apiContent
                    .getSocietyFromPro({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        pid: this.pid.id,
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                                pid: this.pid.id,
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        this.societyOptions =res.data.filter(item=>{
                            return this.$store.state.user.if_default_finance ===1 ? item.id ===1 : item.id ===2
                        })
                        if (this.societyOptions.length === 1 && !this.society) this.society = this.societyOptions[0].id;
                    })
                    .catch(() => {
                        this.societyOptions = [];
                    });
            },
            //  获取学会
            init() {
                this.$api.apiContent
                    .getProject({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        res.data.map(item => {
                            item.name = this.$tools.ZHDate(new Date(item.start_time * 1000)) + " 至 "
                                + this.$tools.ZHDate(new Date(item.end_time * 1000)) + '/' + item.chairman + '/' + item.name
                        })
                        this.projectOptions = res.data;
                    })
                    .catch(() => {
                        this.projectOptions = [];
                    });
                if (this.id) {
                    this.$api.apiContent
                        .getDaokuan({
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            id: this.id,
                            sign: this.$tools.SHA256(
                                {
                                    token: this.$store.state.user.token,
                                    timestamp: this.$tools.requestTime(),
                                    id: this.id,
                                },
                                this.$store.state.user.key
                            ),
                        })
                        .then((res) => {
                            this.trueName = res.data.shenpi[0];
                            this.shenpi = res.data.shenpi
                                .map((item) => {
                                    return item.user_id;
                                })
                                .join(",");
                            this.copyMembers = res.data.copy;
                            this.copy = res.data.copy
                                .map((item) => {
                                    return item.user_id;
                                })
                                .join(",");
                            this.society = res.data.sid;
                            this.sign_code = res.data.sign_code;
                            res.data.project.time =
                                this.$tools.ZHDate(
                                    new Date(res.data.project.start_time * 1000)) +
                                " 至 " +
                                this.$tools.ZHDate(new Date(res.data.project.end_time * 1000));
                            res.data.project.id = res.data.pid;
                            this.pid = res.data.project;
                            this.pName =  this.$tools.ZHDate(new Date(res.data.project.start_time * 1000)) + " 至 "
                                + this.$tools.ZHDate(new Date(res.data.project.end_time * 1000)) + '/' + res.data.project.chairman + '/' +  res.data.project.name;
                            this.agreementId = res.data.zid;
                            this.agreementName =
                                res.data.zanzhu.name +
                                "(" +
                                res.data.zanzhu.corporate_name +
                                ")";
                            this.corporate_name = res.data.zanzhu.name;
                            this.contacts = res.data.contacts;
                            this.amounts = parseFloat(Number(res.data.amount).toFixed(6));
                            this.self_amount = parseFloat(Number(res.data.self_amount).toFixed(6));
                            this.meeting_decimal = parseFloat(Number(res.data.meeting_decimal).toFixed(6));
                            this.remark = res.data.remark;
                            this.daokuan_date = res.data.daokuan_date;
                            this.getSociety();
                        })
                        .catch(() => {
                        });
                }
            },
            backPage() {
                this.$tools.goBack();
            },
            //审批人
            setApproval(members) {
                this.shenpi = members;
            },
            //抄送人
            setMember(members) {
                this.copy = members;
            },
        },
        watch: {
            society() {
                this.getAgreement();
            },
        },
    };
</script>

<style scoped lang="scss">
    .page-box {
        .page-title {
            height: 40px;
            margin-bottom: 10px;

            span {
                font-size: 18px;
                line-height: 36px;
                color: #999999;

                b {
                    color: #333;
                    font-weight: normal;
                }
            }

            div {
                color: #3b77e7;
                font-size: 20px;
                cursor: pointer;

                i {
                    display: block;
                    width: 24px;
                    height: 24px;
                    text-align: center;
                    line-height: 24px;
                    margin-right: 5px;
                    border-radius: 100%;
                    color: #fff;
                    font-size: 14px;
                    background-color: #3b77e7;
                }
            }
        }

        .sub-merchants-info {
            width: 100%;
            background-color: #ffffff;
            border-radius: 6px;
            padding: 40px 25px;
            min-height: 800px;

            span {
                color: #000000;
                font-size: 24px;
            }

            .sub-merchants-container {
                width: 60%;
                display: flex;
                flex-direction: column;
                margin-top: 20px;
                .tip-red{
                    color: red;
                    font-weight: bold;
                    margin-left: 23%;
                    margin-bottom: 20px;
                }
                .input-item {
                    display: flex;
                    margin-bottom: 20px;
                    flex-direction: row;
                    align-items: center;
                    overflow: hidden;

                    .money {
                        flex-shrink: 0;
                        margin-left: 10px;
                    }
                    .money-count {
                        flex-shrink: 0;
                        margin-left: 10px;
                        font-size: 16px;
                    }

                    .input-item-title {
                        width: 20%;
                        text-align: center;
                        font-size: 16px;
                        margin-right: 20px;
                        color: #000000;
                        flex-shrink: 0;
                    }

                    .input-item-desc {
                        width: 20%;
                        align-self: flex-start;
                        margin-top: 10px;
                        margin-right: 20px;
                        text-align: center;
                        font-size: 16px;
                        color: #000000;
                        flex-shrink: 0;
                    }

                    .el-input,
                    .el-select,
                    .el-textarea,
                    .el-radio-group,
                    .el-date-editor,
                    .el-upload {
                        flex-grow: 1;
                        resize: none;
                    }
                }

                > .el-button {
                    margin-top: 30px;
                    align-self: center;
                    width: 40%;
                }
            }
        }
    }
</style>
